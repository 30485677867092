.react-international-phone-input-container .phoneInput {
  width: 100%;
  border-left: 0;
  padding: 12px 16px 12px 0;
}
.react-international-phone-input-container .countrySelectorButton {
  border-right: 0;
  padding: 12px 5px 12px 16px;
}
.react-international-phone-input-container .countrySelectorDropDown {
  width: calc(100vw - 100px);
  outline: none;
  margin-top: 8px;
}
@media (min-width: 36rem) {
  .react-international-phone-input-container .countrySelectorDropDown {
    width: 320px;
  }
}
.react-international-phone-input-container .countrySelectorDropDownListItem {
  padding: 8px 16px;
}
.react-international-phone-input-container .countrySelectorDropDownListItem:hover {
  background-color: var(--background-page, #fcfcf9);
}

.react-international-phone-input-container .countrySelectorDropDownListItemFlag {
  border-radius: 0;
}

.react-international-phone-input-container .countrySelectorDropdownArrow {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z' fill='%231d1d1d'%3E%3C/path%3E%3Cpath d='M0-.75h24v24H0z' fill='none'%3E%3C/path%3E%3C/svg%3E");
  width: 16px;
  height: 16px;
  border: 0;
}
:root {
  --react-international-phone-height: calc(3 * var(--lendi-ui-size));
  --react-international-phone-dropdown-item-height: calc(3 * var(--lendi-ui-size));
  --react-international-phone-border-color: var(--border-interaction, #8f8f8e);
  --react-international-phone-border-radius: 0;
  --react-international-phone-font-size: max(14px, 1rem);
  --react-international-phone-selected-dropdown-item-background-color: var(--fill-selected, #f3e6fc);
  --react-international-phone-text-color: var(--text-main, #1d1d1d);
  --react-international-phone-selected-dropdown-item-text-color: var(--text-main, #1d1d1d);
  --react-international-phone-dropdown-item-dial-code-color: var(--semantic-text-main, #1d1d1d);
  --react-international-phone-dropdown-item-text-color: var(--semantic-text-main, #1d1d1d);
  --react-international-phone-flag-width: 34px;
  --react-international-phone-flag-height: 26px;
}
.react-international-phone-country-selector-dropdown__list-item--selected {
  font-weight: 600;
}
